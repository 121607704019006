<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :profile="profile"/>


        <div class="container" style="margin-top:30px">
            <div class="row" v-if="course.slug != undefined">
                <div class="col-6">
                    <router-link :to="'/curso/'+course.slug">Regresar</router-link>
                    <div class="shadow-sm p-3 mb-5 bg-body rounded">
                        <img :src="`/storage/courses/${course.cover}`" class="img-fluid" alt="" title="">
                        {{course.name}}
                        <h3 class="text-right">${{course.price | number_format}} MXN</h3>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Cupón de descuento" v-model="coupon">
                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="validateCoupon">Aplicar</button>
                        </div>
                        <div v-if="isCouponValid !=null">
                            <p v-if="isCouponValid" class="alert alert-success">Cupón Válido. {{this.coupon_description}}</p>
                            <p v-else class="alert alert-danger">Cupón inválido</p>
                        </div>
                        <template v-if="paymentType !='coupon'">
                            <h4>Forma depago</h4>
                            <div class="row mb-5">
                            <div class="col-4 text-center">
                                <div class="img-thumbnail paymethod" :class="{'bg-active':paymentType=='card'}" @click="paymentType='card'">
                                    Tarjeta de Crédito/débito
                                </div>
                            </div>
                             <div class="col-4 text-center">
                                 <div class="img-thumbnail paymethod" :class="{'bg-active':paymentType=='transfer'}" @click="paymentType='transfer'">
                                 Transferencia
                                 </div>
                            </div>
                             <div class="col-4 text-center">
                                 <div class="img-thumbnail paymethod" :class="{'bg-active':paymentType=='oxxo'}" @click="paymentType='oxxo'">
                                 Pago en oxxo
                                 </div>
                            </div>
                            </div>
                        </template>
                        <div class="row mb-5">
                            <div class="col-12">
                                 <div v-if="paymentType=='coupon'">
                                    <button class="btn btn-golden mt-3 p-3 w-100 btn-buynow" @click.prevent="pay">Pagar ahora</button>

                                </div>
                                <div v-if="paymentType=='card'">
                                    <form class="row" method="POST" id="card-form">
                                        <span class="card-errors"></span>
                                        <div class="col-md-12">
                                            <label>
                                            <span>Nombre del tarjetahabiente</span>
                                            </label>
                                            <input class="form-control" type="text" size="20" data-conekta="card[name]" v-model="card.name">
                                        </div>
                                        <div class="col-md-12">
                                            <label>
                                            <span>Número de tarjeta de crédito</span>
                                            </label>
                                            <input class="form-control" type="text" size="20" data-conekta="card[number]" v-model="card.number">
                                        </div>
                                        <div class="col-md-4">
                                            <label>
                                            <span>Exp. Mes</span>
                                            </label>
                                            <input class="form-control" type="text" size="2" data-conekta="card[exp_month]" v-model="card.exp_month">
                                        </div>
                                        <div class="col-md-4">
                                            <label>
                                            <span>Exp. Año</span>
                                            </label>
                                            <input class="form-control" type="text" size="4" data-conekta="card[exp_year]" v-model="card.exp_year">
                                        </div>
                                        <div class="col-md-4">
                                            <label>
                                            <span>CVC</span>
                                            </label>
                                            <input class="form-control" type="text" size="4" data-conekta="card[cvc]" v-model="card.cvc">
                                        </div>

                                        <div class="col-md-12">
                                            <span class="alert alert-danger" v-if="conekta.error">{{conekta.message}}</span>
                                        </div>
                                        </form>
                                <button class="btn btn-golden mt-3 p-3 w-100 btn-buynow" @click.prevent="pay">Pagar ahora</button>

                                </div>
                                <div v-if="paymentType=='transfer'">
                                    <b>Instrucciones:</b><br/>
                                    <ul>
                                        <li>Se generará una orden de compra que será enviada a tu correo.</li>
                                        <li>Tienes 72 horas para hacer la transferencia.</li>
                                        <li>Después de realizar la transferencia envía el comprobante a cursos@tmtcapacitacion.com</li>
                                        <li>24 horas después de verificado tu pago, podrás acceder al curso</li>
                                    </ul>
                                    <button class="btn btn-golden mt-3 p-3 w-100 btn-buynow" @click.prevent="pay">Generar orden</button>
                                </div>
                                <div v-if="paymentType=='oxxo'">
                                    <b>Instrucciones:</b><br/>
                                    <ul>
                                        <li>Se generará una orden de compra que será enviada a tu correo.</li>
                                        <li>Tienes 72 horas para pagar en oxxo.</li>
                                        <li>24 horas después de verificado tu pago, podrás acceder al curso</li>
                                    </ul>
                                    <button class="btn btn-golden mt-3 p-3 w-100 btn-buynow" @click.prevent="pay">Generar orden</button>

                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
  components:{
      Header,
      Footer
  },
  name: 'Course',
  data() {
      return {
          user:this.$store.getters.getUser,
          profile: this.$store.getters.getUser,
          showBuyForm: false,
          showTaxForm: false,
          step: 1,
          course: {},
          courses: [],
          coupon: "",
          coupon_description: "",
          isCouponValid: null,
          paymentType: 'card',
          //card: {number:"4242424242424242", name:"carlos", exp_month:"12", exp_year:"2022",cvc:"123"},
          card: {number:"", name:"", exp_month:"", exp_year:"",cvc:""},
          conekta: {error: false, message:"", key: "key_KxAjKB7TFWiqnsrF2yRVdoQ"},
          buyOrder: {course_id:"", coupon_code:"", conektaToken:"",paymentType:"" }
      }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
    script.async = true
    document.body.appendChild(script)

    this.loadCourse(this.$route.params.courseName)
        .then((response)=>{
            this.course = response
      }).catch((error)=>{
          console.log(error);
    })
    this.loadConfig();


  //     Conekta.setPublicKey('key_KxAjKB7TFWiqnsrF2yRVdoQ');

  },
  filters: {
    number_format: function (value)  {
        if (value == null) {
            return '-';
        }
        return parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  methods: {
      validateCoupon(){
          switch(this.coupon) {
              case 'TMTPILOTO':
                  this.course.price = 0;//this.course.price-400;
                  this.isCouponValid = true;
                  this.paymentType = 'coupon';
                  this.coupon_description = "-100%";
              break;
              case 'TMT30':
                this.course.price = Math.round(this.course.price*.7, 2);
                this.isCouponValid = true;
                this.paymentType = 'coupon';
                this.coupon_description = "-30%";
              break;
               case 'TMTMINIMO':
                this.course.price = 20;
                this.isCouponValid = true;
                this.coupon_description = "mínimo de compra";
                this.buyOrder.coupon_code = "TMTMINIMO"
              break;
              default:
                this.isCouponValid = false;
                this.paymentType = 'card'
          }
      },
      pay() {
        this.step = 4;
        this.buyOrder.user_id = this.user.id
        this.buyOrder.course_id = this.course.id
        this.buyOrder.paymentType = this.paymentType
        this.buyOrder.coupon_code = this.coupon

        switch(this.paymentType){
            case 'coupon':
                 this.sendOrder();
            break;
            case 'oxxo':
                 this.sendOrder();
            break;
            case 'transfer':
                 this.sendOrder();
            break;
            case 'card':
                window.Conekta.setPublicKey(this.conekta.key)
                window.Conekta.Token.create({card: this.card},token => {
                    this.buyOrder.conektaToken = token
                    this.sendOrder();
                },error => {
                    this.conekta.message = error.message_to_purchaser;
                    this.conekta.error = true;
                })
            break;
        }
      },
      sendOrder() {
        let dataToSend = {buyOrder : this.buyOrder}
          axios.post(process.env.VUE_APP_APIURL + 'pay', dataToSend)
            .then((response) => {
                if(response.data.error) {
                    alert("Hubo un error con tu pago:" + response.data.error_message)
                } else {
                    //this.$store.dispatch('magiclink', response.data.data.magiclink);
                    window.location.href = "/profile/payments/" + response.data.data.invoice.id;
                }
            })
            .catch(function (error) {
                alert(error)

            })

      },
       loadCourse(courseSlug) {
        return new Promise((resolve, reject)=> {
            axios.get(process.env.VUE_APP_APIURL + 'courses/' + courseSlug)
              .then((response)=>{
                resolve(response.data.data);
            }).catch((error)=>{
                reject(error);
            })
        })
      },
      loadConfig(){
           return new Promise((resolve, reject)=> {
            axios.get(process.env.VUE_APP_APIURL + 'config/')
              .then((response)=>{
                  this.conekta.key = response.data.data.publicKey;
                resolve();
            }).catch((error)=>{
                reject(error);
            })
        })
      }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.bg-active{
    background: #152F44;
    color:#FFF
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
.btn-buynow{font-size:30px}
.paymethod{ height:60px}
</style>
